import Vue from 'vue'
import VueRouter from 'vue-router'
import backmenu from './backmanage.js'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      title: '登录',
      iscache: false,
      isframe: true,
    }
  },
  {
    path: '/login/:shareid',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      title: '登录',
      iscache: false,
      isframe: true,
    }
  },
  {
    path: '/switch-enterprise',
    name: 'SwitchEnterprise',
    component: () => import('../views/SwitchEnterprise.vue'),
    meta: {
      title: '切换企业',
      iscache: false,
      isframe: true,
    }
  },
  {
    path: '/create-company',
    name: 'createCompany',
    component: () => import('../views/CreateCompany.vue'),
    meta: {
      title: '创建企业',
      iscache: false,
      isframe: true,
    }
  },
  {
    path: '/join-enterprise',
    name: 'JoinEnterprise',
    component: () => import('../views/JoinEnterprise.vue'),
    meta: {
      title: '加入企业',
      iscache: false,
      isframe: true,
    }
  },
  {
    path: '/versionLogs',
    name: 'VersionLogs',
    component: () => import('../views/HomePage/VersionLogs.vue'),
    meta: {
      title: '日志',
      iscache: false,
      isframe: true,
    }
  },
  {
    path: '/product/:id',
    name: 'product',
    component: () => import('../views/ProductLibrary/CommonTable.vue'),
    meta: {
      iscache: false,
      isframe: false,
      refresh: true,
    }
  },
  {
    path: '/product/:id/:type',
    name: 'product-detail',
    component: () => import('../views/ProductDetail/index.vue'),
    meta: {
      iscache: false,
      isframe: true,
    }
  },
  {
    path: '/solution/:id',
    name: 'solution',
    component: () => import('../views/ProductLibrary/index.vue')
  },
  {
    path: '/demonstration/:id',
    name: 'demonstration',
    component: () => import('../views/ProductLibrary/CommonTable.vue'),
    meta: {
      iscache: false,
      isframe: false,
      refresh: true,
    }
  },
  {
    path: '/stream/:id',
    name: 'stream',
    component: () => import('../views/StreamManagement/index.vue')
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('../views/Contacts/index.vue'),
    meta: {
      iscache: false,
      isframe: false,
    }
  },
  {
    path: '/getdetailbyshareid',
    name: 'getdetailbyshareid',
    component: () => import('../views/ShareDetail/index.vue'),
    meta: {
      iscache: false,
      isframe: true,
    }
  },
  {
    path: '/share',
    name: 'share',
    component: () => import('../views/Share/index.vue'),
    meta: {
      iscache: false,
      isframe: false,
      refresh: true,
    }
  },
  {
    path: '/notabs',
    name: 'notabs',
    component: () => import('../views/Notabs/index.vue')
  },
  {
    path: '/template',
    name: 'Template',
    component: () => import('../views/Template/index.vue')
  },
  {
    path: '/playeditor',
    name: 'playeditor',
    component: () => import('../views/PlayEditor/index.vue'),
    meta: {
      iscache: false,
      isframe: true,
    }
  },
  {
    path: '/playeditorpreview',
    name: 'playeditorpreview',
    component: () => import('../views/PlayEditor/index.vue'),
    meta: {
      iscache: false,
      isframe: true,
    }
  },
  {
    path: '/businesscard',
    name: 'businesscard',
    component: () => import('../views/BusinessCard/index.vue')
  },
  {
    path: '/companyspace',
    name: 'companyspace',
    component: () => import('../views/CompanyCard/index.vue')
  },
  {
    path: '/collect',
    name: 'collect',
    component: () => import('../views/MyCollect/index.vue')
  },
  {
    path: '/recycle',
    name: 'recycle',
    component: () => import('../views/RecycleBin/index.vue'),
  },
  {
    path: '/file',
    name: 'file',
    component: () => import('../views/FileResources/index.vue')
  },
  {
    path: '/product-edit',
    name: 'product-edit',
    component: () => import('../views/ProdudtEdito/index.vue'),
    meta: {
      iscache: false,
      isframe: true,
    }
  },
  {
    path: '/demo-mode',
    name: 'demo-mode',
    component: () => import('../views/DemoMode/index.vue'),
    meta: {
      iscache: false,
      isframe: true,
    }
  },
  {
    path: '/product-file',
    name: 'product-file',
    component: () => import('../views/ProductFile/index.vue'),
    meta: {
      iscache: false,
      isframe: true,
    }
  },
  {
    path: '/product-preview',
    name: 'product-preview',
    component: () => import('../views/DemoMode/preview.vue'),
    meta: {
      iscache: false,
      isframe: true,
    }
  },
  {
    path: '/column-preview',
    name: 'column-preview',
    component: () => import('../views/ProdudtEdito/columnpreview.vue'),
    meta: {
      iscache: false,
      isframe: true,
    }
  },
  {
    path: '/article/:id',
    name: 'article',
    component: () => import('../views/ArticleView/CommonArticle.vue')
  },
  {
    path: '/articlepreview/:id',
    name: 'articlepreview',
    component: () => import('../views/ArticleView/components/ArticlePreview.vue'),
    meta: {
      iscache: false,
      isframe: true,
    }
  },
  {
    path: '/hometable',
    name: 'hometable',
    component: () => import('../views/HomeTable/index.vue'),
    meta: {
      iscache: false,
      refresh: true,
    }
  },
  {
    path: '/synthesis/:id',
    name: 'synthesis',
    component: () => import('../views/SynthesisShow/index.vue'),
    meta: {
      iscache: false,
      refresh: true,
    }
  },
  {
    path: '/backstage-managed',
    redirect: '/backstage-managed/member',
    name: 'backstage-managed',
    component: () => import('../views/BackgroundManagement/index.vue'),
    meta: {
      title: '后台管理',
      iscache: false,
      isframe: true,
    },
    children: backmenu
  },
  {
    path: '/backstage-setting',
    redirect: '/backstage-setting/userinfo',
    name: '/backstage-setting',
    component: () => import('../views/BackgroundManagement/NormalSet.vue'),
    meta: {
      title: '设置',
      iscache: false,
      isframe: true,
    },
    children: [
      {
        path: 'userinfo',
        component: () => import('../views/BackgroundManagement/UserInformation/index.vue'),
        meta: {
          title: '设置',
          iscache: false,
          isframe: true,
        }
      },
      {
        path: 'keyboard',
        component: () => import('../views/BackgroundManagement/KeyboardConfigutation/index.vue'),
        meta: {
          title: '设置',
          iscache: false,
          isframe: true,
        },
      },
    ]
  },
];

const router = new VueRouter({
  routes
})

export default router