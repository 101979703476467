import request from "@/utils/appServer";
import common from "../utils/common";

class File {
  // 创建系统目录
  static async createBaseFolder() {
    try {
      const res = await request.post("/sales-show-4.0/file/createBaseFolder");
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 上传封面
  static async uploadCoverPhoto(data) {
    try {
      const res = await request.post("/sales-show-4.0/subject/file/uploadCoverPhoto", data);
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  // 获取主题封面列表
  static async getCoverList(subjectId) {
    try {
      const res = await request.get("/sales-show-4.0/subject/file/queryCoverPhotoList", { params: { subjectId } });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 获取文件栏目分类树
  static async queryFileByCategoryTree(data) {
    try {
      const res = await request.get("/sales-show-4.0/file/queryFileByCategoryTree", { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 保存文件分类树
  static async saveBatchFileCategory(data) {
    try {
      const res = await request.post("/sales-show-4.0/file/saveBatchFileCategory", data, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 获取文件栏目一级分类列表
  static async queryFileByFirstCategory(data) {
    try {
      const res = await request.get("/sales-show-4.0/file/queryFileByFirstCategory", { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 根据一级分类获取文件栏目二级分类列表
  static async queryFileBySecondCategory(data) {
    try {
      const res = await request.get("/sales-show-4.0/file/queryFileBySecondCategory", { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 获取分类下文件列表
  static async queryFileByCategory(data) {
    try {
      const res = await request.get("/sales-show-4.0/file/queryFileByCategory", { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : new common.Pagination();
    } catch (error) {
      return new common.Pagination();
    }
  }
  // 获取主题文件类栏目文件分类列表
  static async queryColumnFileList(data) {
    try {
      const res = await request.get("/sales-show-4.0/subject/file/queryColumnFileList", { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : new common.Pagination();
    } catch (error) {
      return new common.Pagination();
    }
  }
  // 获取模板文件类栏目文件分类列表
  static async queryTemplateColumnFileList(data) {
    try {
      const res = await request.get("/sales-show-4.0/template/queryTemplateColumnFileList", { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : new common.Pagination();
    } catch (error) {
      return new common.Pagination();
    }
  }
  // 获取文件目录下的文件列表
  static async queryFileByFolderId(data) {
    try {
      const res = await request.get("/sales-show-4.0/file/queryFileByFolderId", { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : new common.Pagination();
    } catch (error) {
      return new common.Pagination();
    }
  }
  // 获取文件目录下的文件列表(没有token)
  static async queryFileByFolderIdNoToken(data) {
    try {
      const res = await request.get("/sales-show-4.0/file/queryFileByFolderIdNoToken", { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : new common.Pagination();
    } catch (error) {
      return new common.Pagination();
    }
  }
  // 文件类栏目-上传文件
  static async uploadColumnFile(data) {
    try {
      const res = await request.post("/sales-show-4.0/file/uploadColumnFile", data);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 删除文件
  static async deleteFile(fileId, isClear = 0, isCurrent = 1) {
    try {
      const res = await request.delete(`/sales-show-4.0/file/deleteFile?fileId=${fileId}&isClear=${isClear}&isCurrent=${isCurrent}`, {}, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 创建文件目录
  static async createFileFolder(data) {
    try {
      const res = await request.post("/sales-show-4.0/file/createFileFolder", data, true);
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  // 删除文件夹
  static async deleteFolder(folderId, isClear = 0, isDeleteChilds = 1) {
    try {
      const res = await request.delete(`/sales-show-4.0/file/deleteFolder?folderId=${folderId}&isClear=${isClear}&isDeleteChilds=${isDeleteChilds}`, {}, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 文件、文件夹重命名
  static async renameFile(fileType, targetId, newName) {
    try {
      const res = await request.put(`/sales-show-4.0/file/realNameFile?fileType=${fileType}&targetId=${targetId}&newName=${newName}`, null, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 编辑文件说明
  static async updateFileRemark(fileId, fileDescribe) {
    try {
      const res = await request.put(`/sales-show-4.0/file/updateFileRemark?fileId=${fileId}&fileDescribe=${fileDescribe}`, null, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 编辑文件夹说明
  static async updateFolderRemark(folderId, folderDescribe) {
    try {
      const res = await request.put(`/sales-show-4.0/file/updateFolderRemark?folderId=${folderId}&folderDescribe=${folderDescribe}`, null, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 单个文件下载
  static async downLoadFile(data) {
    try {
      const res = await request.get('/sales-show-4.0/file/downLoadFile', { params: data });
      if(res.data.code === 1014){
        return "notsupport"
      }else{
        return res.data.code === 200 && res.data.result ? res.data.result : false;
      }
    } catch (error) {
      return false;
    }
  }
   // 单个文件下载/预览(没有token)
   static async downLoadFileNoToken(data) {
    try {
      const res = await request.get('/sales-show-4.0/file/downLoadFileNoToken', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  // 获取文件版本
  static async getByFileVersion(fileId) {
    try {
      const res = await request.get('/sales-show-4.0/file/getByFileVersion', { params: { fileId } });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }


  // 获取租户下文件系统目录列表(右侧云盘)
  static async queryFileSystemList(fileName = "") {
    try {
      const res = await request.get('/sales-show-4.0/file/queryFileSystemList', { params: { fileName } });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 复制移动文件或文件夹(从企业云盘拖拽文件放到相关文件列表里)
  static async copyMoveFile(data) {
    try {
      const res = await request.put("/sales-show-4.0/file/copyMoveFile", data, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 富文本导入office文件
  static async importOfficeFile(data, options) {
    try {
      const res = await request.post("/sales-show-4.0/file/importOfficeFile", data, false, false, options);
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  // 获取富文本导入文件解析进度
  static async getPareFileProgress(id) {
    try {
      const res = await request.get("/sales-show-4.0/file/getPareFileProgress?fileKey=" + id);
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  // 获取文件属性详情
  static async getFileAttribute(data) {
    try {
      const res = await request.get("/sales-show-4.0/file/getFileAttributeDetail", { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : {};
    } catch (error) {
      return {};
    }
  }
  // 获取文件夹属性详情
  static async getFolderAttribute(data) {
    try {
      const res = await request.get("/sales-show-4.0/file/getFolderAttributeDetail", { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : {};
    } catch (error) {
      return {};
    }
  }
  // 获取文件或文件夹属性值下拉列表
  static async getAttributeValueById(dictId) {
    try {
      const res = await request.get("/sales-show-4.0/file/getAttributeValueById", { params: { dictId } });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 保存文件属性
  static async saveFileAttribute(data) {
    try {
      const res = await request.post("/sales-show-4.0/file/saveFileAttribute", data, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 删除文件属性
  static async deleteAttribute(dataId, dictId) {
    try {
      const res = await request.delete("/sales-show-4.0/file/deleteFileAttribute", { params: { dataId, dictId } }, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 更换文件或文件夹属性值
  static async updateFileValue(data) {
    try {
      const res = await request.post("/sales-show-4.0/file/updateFileValue", data, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 选择文件或文件夹关联分类
  static async updateFileCategory(data) {
    try {
      const res = await request.post("/sales-show-4.0/file/updateFileCategory", data, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 获取文件或文件夹关联列表
  static async getFileRelatedMatters(identifyId) {
    try {
      const res = await request.get("/sales-show-4.0/file/getFileRelatedMatters", { params: { identifyId } });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 添加文件关联事项
  static async addFileEvent(data) {
    try {
      const res = await request.post("/sales-show-4.0/file/addFileEvent", data);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 删除文件关联事项
  static async deleteEvent(eventId) {
    try {
      const res = await request.delete("/sales-show-4.0/file/deleteEvent", { params: { eventId } });
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 获取文件或文件夹操作日志列表
  static async getFileLog(data) {
    try {
      const res = await request.get("/sales-show-4.0/file/getFileLog", { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : new common.Pagination();
    } catch (error) {
      return new common.Pagination();
    }
  }
  // 上传文件
  static async uploadFile(data, options) {
    try {
      const res = await request.post('/sales-show-4.0/file/upload', data, false, false, options);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 获取文档分类下文件列表
  static async queryDocFileByCategory(data) {
    try {
      const res = await request.get('/sales-show-4.0/file/queryDocFileByCategory', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 获取视频分类下文件列表
  static async queryVideoFileByCategory(data) {
    try {
      const res = await request.get('/sales-show-4.0/file/queryVideoFileByCategory', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 获取模型分类下文件列表
  static async queryModFileByCategory(data) {
    try {
      const res = await request.get('/sales-show-4.0/file/queryModFileByCategory', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 获取模块文件夹属性列表
  static async queryModuleAttributeList(moduleId) {
    try {
      const res = await request.get('/sales-show-4.0/file/queryModuleAttributeList', { params: { moduleId } });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 模块文件添加全局属性
  static async addModuleGlobalAttribute(data) {
    try {
      const res = await request.post('/sales-show-4.0/file/addModuleGlobalAttribute', data);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 获取文件或文件夹属性值下拉列表
  static async getAttributeValueById(dictId) {
    try {
      const res = await request.get('/sales-show-4.0/file/getAttributeValueById', { params: { dictId } });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 删除模块全局属性
  static async deleteModuleAttribute(data) {
    try {
      const res = await request.delete('/sales-show-4.0/file/deleteModuleAttribute', { params: data });
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 删除模块全局属性的属性值
  static async deleteDictItem(data) {
    try {
      const res = await request.delete('/sales-show-4.0/file/deleteDictItem', { params: data });
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 属性值选择保存
  static async updateAttributeValueSelect(data) {
    try {
      const res = await request.post('/sales-show-4.0/file/updateAttributeValueSelect', data);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 更新分片文件版本
  static async updateFileSplitVersion(data, options) {
    try {
      const res = await request.post('/sales-show-4.0/file/updateFileSplitVersion', data, false, false, options);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 文件类栏目-文件分片上传
  static async uploadColumnFileSplit(data, options) {
    try {
      const res = await request.post('/sales-show-4.0/file/uploadColumnFileSplit', data, false, false, options);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 文件类栏目-上传文件检查
  static async checkFile(data) {
    try {
      const res = await request.get('/sales-show-4.0/file/checkFile', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }

  // 获取产品下文件列表
  static async queryFileBySubject(data) {
    try {
      const res = await request.get('/sales-show-4.0/file/queryFileBySubject', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : new common.Pagination();
    } catch (error) {
      return new common.Pagination();
    }
  }
  // 获取产品下文件列表
  static async queryFileBySubjectNoToken(data) {
    try {
      const res = await request.get('/sales-show-4.0/file/queryFileBySubjectNoToken', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : new common.Pagination();
    } catch (error) {
      return new common.Pagination();
    }
  }
  // 创建主题下文件目录
  static async createSubjectFileFolder(data) {
    try {
      const res = await request.post('/sales-show-4.0/file/createSubjectFileFolder', data);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 主题目录下-文件分片上传
  static async uploadSubjectFileSplit(data, options) {
    try {
      const res = await request.post('/sales-show-4.0/file/uploadSubjectFileSplit', data, false, false, options);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 文件资源-获取文件资源目录和系统根目录
  static async getRootFolder() {
    try {
      const res = await request.get('/sales-show-4.0/file/getRootFolder');
      return res.data.code === 200 && res.data.result ? res.data.result : "";
    } catch (error) {
      return "";
    }
  }
  // 创建镜像文件或文件夹
  static async createFileRelation(data) {
    try {
      const res = await request.post('/sales-show-4.0/file/createFileRelation', data);
      return res.data.code === 200;
    } catch (error) {
      return false
    }
  }
  // 获取文件夹列表
  static async getFolderList(folderId) {
    try {
      const res = await request.get('/sales-show-4.0/homeContents/getFolderList', { params: { folderId } });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
}

export { File }