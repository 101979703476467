import request from "@/utils/appServer";
class Login {
  // 账号密码登录接口
  static async login(data) {
    try {
      const res = await request.post('/sales-show-4.0/sys/login', data);
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  // 登陆信息接口
  static async getUserInfo() {
    try {
      return await request.get('/sales-show-4.0/sys/user/queryUserByToken');
    } catch (error) {
      console.log(error,99);
      return false;
    }
  }
  // 获取销售展示平台在SaaS的产品id
  static async getSystemProductId() {
    try {
      const res = await request.get('/sales-show-4.0/sys/getSystemProductId');
      return res.data.code === 200 && res.data.result ? res.data.result : "";
    } catch (error) {
      return "";
    }
  }
}

export { Login }