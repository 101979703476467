function getRootColor(colorName) {
  return window.getComputedStyle(document.documentElement).getPropertyValue(colorName);
}

export default Object.freeze({
  primaryColor: getRootColor('--color-primary'),
  infoColor: getRootColor('--color-info'),
  defaultFirstCategoryName: "演示材料",
  onlyOfficeType: ".docx,.wps,.xlsx,.et,.pptx,.dps,.doc,.txt,.xls,.csv,.ppt,.pdf",
  videoType: ".mp4,.avi,.rmvb,.webm,.wmv,.mkv,.mov",
  ImageType: ".jpg,.jpeg,.png,.gif",
  modelType: ".pak,.rvt,.3dxml,.gim,.ifc,.igms,.fbx,.3dm,.stp,.gltf,.skp,.glb,.dgn,.nwd,.nwc",
  deleteMessage: {
    isDelete: true,
    title: "是否确认删除?",
    titleColor: "#ff7859",
    cancelButtonText: "再想想",
    confirmButtonText: "确认删除",
    confirmButtonBgColor: "#ff7859",
  }
})